import "./App.css";
import logo from "./assets/images/logo.png";

function App() {
  return (
    <div id="container">
      <div className="profile">
        <div className="image-lays"></div>
      </div>

      <div className="wrapper-links">
        {/* <a
          className="link shake"
          href="https://ebook.ligandoideiasredacao.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          📚
          <span
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              fontWeight: "800",
            }}
          >
            E-book: CHECKLIST ENEM 2023
          </span>
          📖
        </a> */}

        <a
          className="link shake"
          href="https://preinscricoes.ligandoideiasredacao.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          Reservas 2024 (ESGOTADAS) 🔥
        </a>

        <a
          className="link"
          href="https://ligandoideiasredacao.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          Site institucional
        </a>

        <a
          className="link"
          href="https://plataforma.ligandoideiasredacao.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          Plataforma de correção própria
        </a>

        <h2>Contatos:</h2>

        <a
          className="link"
          href="https://api.whatsapp.com/send?phone=5534988777797"
          target="_blank"
          rel="noopener noreferrer"
        >
          WhatsApp
        </a>

        <a
          className="link"
          href="https://www.instagram.com/ligandoideiasredacao/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>

        <a
          className="link"
          href="https://www.facebook.com/LigandoIdeiasRedacao"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>

        <h2>Saiba mais sobre:</h2>

        <a
          className="link"
          href="https://www.instagram.com/p/CniBsxIsBPM/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Aula individual ao vivo
        </a>

        <a
          className="link"
          href="https://www.instagram.com/p/Cnko1DVr3FZ/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Turma noturna ao vivo
        </a>

        <a
          className="link"
          href="https://www.instagram.com/p/CnnMPSsvY0h/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Correção humanizada
        </a>

        <a
          className="link"
          href="https://www.instagram.com/p/CnprE6AMr3-/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mentoria
        </a>
      </div>

      <div className="footer-logo">
        <img src={logo} alt="Ligando Ideias Redação" />
      </div>
    </div>
  );
}

export default App;
